import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { HOST } from './host';
// import GoogleProfileContext from './Google_profile_context';


function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize the history object
  
  // For google login
  const [ user, setUser ] = useState([]);
  const [email, setEmail] = useState('');
  // const [ profile, setProfile ] = useContext(GoogleProfileContext);
  // const profile = useContext(GoogleProfileContext);
  // const [profileDone, setProfileDone] = useState(false);
  
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  function getCookie(name) {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name + '='))
      ?.split('=')[1]; // Get the value of the cookie with the specified name
    return cookieValue ? decodeURIComponent(cookieValue) : null;
  }

  const handleGoogleLogin = async () => {
    const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
      const headers = {
        'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
      };
      const uploadData = new FormData();
      const googleEmail = (await JSON.parse(localStorage.getItem('profile'))).email
      uploadData.append('email', googleEmail);
      console.log(email);
      onLogin();
          navigate('/demo');
      // await fetch(`${HOST}google_check/`, {
      //   method: 'POST',
      //   headers: headers,
      //   body: uploadData,
      // }).then((response) => {
      //   if (!response.ok) {
      //     navigate('/google_profile');
      //   } else {
      //     onLogin();
      //     navigate('/demo');
      //   }
      // })
  }

  useEffect(
    () => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setEmail(res.data.email);
                    // Inside your useEffect after fetching profile data
                    localStorage.setItem('profile', JSON.stringify(res.data));
                    handleGoogleLogin();
                })
                .catch((err) => console.log(err));
        }
    },
    [ user ]
  );

  

  // const logOut = () => {
  //   googleLogout();
  //   // setProfile(null);
  //   // Inside your useEffect after fetching profile data
  //   localStorage.setItem('profile', null);
  //   setProfileDone(false);
  // };

  const handleLogin = async () => {
    // Check if the entered credentials are correct (e.g., hard-coded values)
    if (username === 'arkana' && password === 'ArkBU#24') {
      localStorage.setItem('profile', JSON.stringify({
        email: 'arkana',
      }));
      onLogin();
      // Navigate to the "Demo" page after successful login
      navigate('/demo');
    } else if (username === 'guest_user' && password === 'KI2024') {
      localStorage.setItem('profile', JSON.stringify({
        email: 'guest_user',
      }));
      onLogin();
      navigate('/demo');
    } else {

      const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
      const headers = {
        'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
      };
      const uploadData = new FormData();
      uploadData.append('email', username);
      uploadData.append('password', password);
      await fetch(`${HOST}/api/login/`, {
        method: 'POST',
        headers: headers,
        body: uploadData,
      }).then((response) => {
        if (!response.ok) {
          alert('Invalid credentials. Please try again.');
        } else {
          onLogin();
          navigate('/demo');
        }
      })
      
    }
  };

  return (
    <div className="login">
      <div>

        <div id = "title_login">Login</div>

          <label className="custom-field one">
            <input type="text" placeholder=" " name = "username" value={username} onChange={(e) => setUsername(e.target.value)}/>
            <span className="placeholder">Username (or email)</span>
          </label>

          <br/>

          <label className="custom-field one">
            <input
              type="password"
              placeholder="&nbsp;"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="placeholder">Password</span>
          </label>
                                {/*
          <p className="reg_hint">Do not have an account?  <Link id="reg_link" className="reg_hint" to="/register">Register Here.</Link></p>
          <br/>
          */}

          <button id = "button" onClick={handleLogin}>Log in</button>

          <p id="or"><hr/></p>

          {/* {profileDone ? (null) : (
              <div className='other_login'>
                <button className="login-with-google-btn" onClick={() => login()}>Sign in with Google</button>
              </div>
            )} */}
          <div className='other_login'>
            <button className="login-with-google-btn" onClick={() => login()}>Sign in with Google</button>
          </div>

         
    
  </div>
    </div>
  );
}
export default Login;
