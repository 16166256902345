import React, { useState, useEffect } from 'react';
import { HOST } from './host';

const Image = () => {

    // const [allData, setAllData] = useState([]);
    // const [inputImg, setInputImg] = useState();
    // const [outputImg, setOutputImg] = useState();
    // const [timeDate, setTimeDate] = useState();
    const [imgTimeData, setImgTimeData] = useState([]);

    useEffect(() => {
        getImages();
      }, []);

    function getCookie(name) {
        const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(name + '='))
          ?.split('=')[1]; // Get the value of the cookie with the specified name
        return cookieValue ? decodeURIComponent(cookieValue) : null;
    }

    // const base64ToBlob = (base64, mime) => {
    //     const byteString = atob(base64.split(',')[1]);
    //     const ab = new ArrayBuffer(byteString.length);
    //     const
    // }

    const getImages = async () => {
        const savedProfile = await JSON.parse(localStorage.getItem('profile'));
        const uploadData = new FormData();
        uploadData.append('email', savedProfile.email);
        const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
        const headers = {
            'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
        };
        // Fetch all date, informs of: [{input img1, output img1, time+date}, {input img2, output img2, time+date}, ...]
        fetch(`${HOST}get_input_image/`, {
            headers: headers,
            method: 'POST',
            body: uploadData,
        })
        .then(response => response.json())
        .then(data => {
            setImgTimeData(data);
            // const input64 = data[0]['input'];
            // const output64 = data[0]['output'];
            // const datetime = data[0]['datetime'];

            // console.log("input: ", input64);
            // console.log("output: ", output64);
            // console.log("datatime: ", datetime);

            
            // const inputContainer = document.getElementById('input_container');
            // inputContainer.innerHTML = '';
            // // document.getElementById
            // // data:image/jpeg;base64, ${base64ImageString}
            // base64ImageStrings.forEach(base64ImageString => {
            //     console.log(base64ImageString)
            //     const img = document.createElement('img');
            //     img.src = `data:image/jpeg;base64,${base64ImageString}`;
            //     inputContainer.appendChild(img);
            // })



            // setAllData(data); // NOTE: 'data' is the array of images
            // console.log(data);
            // console.log(data[0]);
          })
        .catch(error => {
            console.error('Error fetching input images:', error);
        });
    }

    return (
        <div className="uploaded_images">
            <table>
                <thead>
                    <tr>
                        <th className='odd'>Input Images</th>
                        <th>Output Images</th>
                        <th className='odd'>Evaluate Time</th>
                    </tr>
                </thead>
                <tbody>
                    
                        {imgTimeData.map((item, index) => (
                            <tr key={index}>
                                <td><img src={`data:image/png;base64,${item['input']}`} alt={`Input ${index + 1}`}></img></td>
                                <td><img src={`data:image/png;base64,${item['output']}`} alt={`Input ${index + 1}`}></img></td>
                                <td>{item['datetime']}</td>
                            </tr>
                        ))}
                    
                </tbody>
            </table>
        </div>
    );
}

export default Image;