import React, { useState, useMemo } from 'react';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useNavigate } from 'react-router-dom';
import { HOST } from './host';

function Register() {
    const navigate = useNavigate(); // Initialize the history object
    
    function getCookie(name) {
        const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(name + '='))
          ?.split('=')[1]; // Get the value of the cookie with the specified name
        return cookieValue ? decodeURIComponent(cookieValue) : null;
    }
    

    // country select bar
    const [country, setCountry] = useState('');
    const countryOption = useMemo(() => countryList().getData(), []);
    const countrySelect = country => {
        setCountry(country)
    }

    // gender select bar
    const [gender, setGender] = useState('');
    const genderOption = [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
        { value: 'Nonbinary', label: 'Nonbinary' },
        { value: 'Genderqueer', label: 'Genderqueer' },
        { value: 'Unlisted', label: 'Unlisted' },
        { value: 'NotSay', label: 'Prefer not to Say' }
      ]
    const genderSelect = gender => {
        setGender(gender);
        console.log(genderOption[0]);
        console.log(genderOption[1]);
    }

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [institution, setInstitution] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    
    const handleReg = () => {

        if (password !== repassword) {
            alert("Please re-enter a correct password!");
            return;
        }

        const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
        const headers = {
            'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
        };

        const uploadData = new FormData();
        uploadData.append('firstName', firstName);
        uploadData.append('lastName', lastName);
        uploadData.append('email', email);
        uploadData.append('dob', dob);
        uploadData.append('institution', institution);
        uploadData.append('password', password);
        uploadData.append('country', country.value);
        uploadData.append('gender', gender.value);
        fetch(`${HOST}create_account/`, {
            method: 'POST',
            headers: headers,
            body: uploadData,
        }).then((res) => {
            navigate('/demo');
            console.log(res);
        })

    }
    
  return (
    <div className="register">
      <div>

        <div id = "title_register">Register</div>
        <form id="infoForm">
            <label className="custom-field one" for="name" placeholder=" ">
                <input placeholder=" " type="text" id="first_name" name="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
                <span className="placeholder"><span className='star'>*</span> First Name</span>
            </label>
            <br></br>

            <label className="custom-field one" for="name">
                <input placeholder=" " type="text" id="last_name" name="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
                <span className="placeholder"><span className='star'>*</span> Last Name</span>
            </label>
            <br></br>

            <label className="custom-field one" for="birthdate">
                <input placeholder=" " type="date" id="birthdate" name="birthdate" value={dob} onChange={(e) => setDob(e.target.value)} ></input>
                <span className="placeholder">* Date of Birth</span>
            </label>
            <br></br>

            <label className="custom-field one" for="email">
                <input placeholder=" " type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                <span className="placeholder"><span className='star'>*</span> Email</span>
            </label>
            <br></br>

            <label className="custom-field one" for="institution">
                <input placeholder=" " type="text" id="institution" name="institution" value={institution} onChange={(e) => setInstitution(e.target.value)} required/>
                <span className="placeholder"><span className='star'>*</span> Institution</span>
            </label>
            <br></br>

            <label className="custom-field one" for="gender">
                <Select placeholder="Gender" className="select" options={genderOption} value={gender} onChange={genderSelect} />
                <span className="placeholder"><span className='star'>*</span> Gender</span>
            </label>
            <br></br>

            <label className="custom-field one" for="country">
                <Select placeholder="Country" className="select" options={countryOption} value={country} onChange={countrySelect} />
                <span className="placeholder"><span className='star'>*</span>Country</span>
            </label>
            <br></br>

            <label className="custom-field one" for="password">
                <input id="password" name="password" placeholder="&nbsp;" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required></input>
                <span className="placeholder"><span className='star'>*</span> Password</span>
            </label>
            <br></br>

            <label className="custom-field one" for="re_password">
                <input id="re_password" name="re_password" placeholder="&nbsp;" type="password" value={repassword} onChange={(e) => setRepassword(e.target.value)} required></input>
                <span className="placeholder"><span className='star'>*</span> Re-enter Password</span>
            </label>
            <br></br>

            <button id="button" type="submit" onClick={handleReg}>Register</button>
        </form>
    
  </div>
    </div>
  );
}
export default Register;
