import kidseg from './images/Website.png';
// import React, { useEffect } from 'react';
// import { HOST } from './host';

const Home = () => {

    // useEffect(() => {
        
    //   }, []);

    return (
        <div className="home">
            <img src={kidseg} alt="kidney_segment" />
            {/* <h2>Smartphone image analysis for real time adequacy assessment during kidney biopsy.</h2> */}
            {/* <hr className='bar'></hr>
            <div className='numUsers' id='numUsers'>Loading...</div>
            <div className='numImages' id='numImages'>Loading...</div> */}

        </div>
    );
}
 
export default Home;
