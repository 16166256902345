import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate } from 'react-router-dom';
import { HOST } from './host';

function Google_profile({ onLogin }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');
    const [institution, setInstitution] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate(); // Initialize the history object

    // country select bar
    const [country, setCountry] = useState('');
    const countryOption = useMemo(() => countryList().getData(), []);
    const countrySelect = country => {
        setCountry(country)
    }

    // gender select bar
    const [gender, setGender] = useState('');
    const genderOption = [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
        { value: 'Nonbinary', label: 'Nonbinary' },
        { value: 'Genderqueer', label: 'Genderqueer' },
        { value: 'Unlisted', label: 'Unlisted' },
        { value: 'NotSay', label: 'Prefer not to Say' }
      ]
    const genderSelect = gender => {
        setGender(gender);
        console.log(genderOption[0]);
        console.log(genderOption[1]);
    }

    function getCookie(name) {
        const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(name + '='))
          ?.split('=')[1]; // Get the value of the cookie with the specified name
        return cookieValue ? decodeURIComponent(cookieValue) : null;
      }
    
      const handleAdmin = async () => {
        // Get google login email
        const savedProfile = await JSON.parse(localStorage.getItem('profile'));
        console.log(savedProfile.email);
        console.log(savedProfile.email);
        console.log(savedProfile.email);
        setEmail(savedProfile.email);
        // Check if the entered credentials are correct (e.g., hard-coded values)
        const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
        const headers = {
          'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
        };
    
        const uploadData = new FormData();
        uploadData.append('firstName', firstName);
        uploadData.append('lastName', lastName);
        uploadData.append('dob', dob);
        uploadData.append('email', savedProfile.email);
        uploadData.append('institution', institution);
        uploadData.append('gender', gender.value);
        uploadData.append('country', country.value);
        uploadData.append('password', 'test_string');
        if (firstName.includes(" ") || firstName.includes(".") || firstName.includes("/")) {
          alert("Please enter the name without special character  (space, \".\", \"/\") included.")
        } else {
          if (institution.trim() === "") {
            alert("Please enter a valid institution name.")
          } else {
            await fetch(`${HOST}create_account/`, {
              method: 'POST',
              headers: headers, // Include the headers with the CSRF token
              body: uploadData,
            })
              .then((res) => {
                console.log(res);
                onLogin();
                navigate('/demo');
              })
          }
        }
      };

    
  return (
    <div className="register">
      <div>

        <div id = "title_register">Complete Account Setup</div>
        <form id="infoForm">
            <label className="custom-field one" for="name" placeholder=" ">
                <input placeholder=" " type="text" id="first_name" name="first_name" required value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                <span className="placeholder"><span className='star'>*</span> First Name</span>
            </label>
            <br></br>

            <label className="custom-field one" for="name">
                <input placeholder=" " type="text" id="last_name" name="last_name" required value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                <span className="placeholder"><span className='star'>*</span> Last Name</span>
            </label>
            <br></br>

            <label className="custom-field one" for="birthdate">
                <input placeholder=" " type="date" id="birthdate" name="birthdate" required value={dob} onChange={(e) => setDob(e.target.value)}/>
                <span className="placeholder">* Date of Birth</span>
            </label>
            <br></br>

            <label className="custom-field one" for="institution">
                <input placeholder=" " type="text" id="institution" name="institution" required value={institution} onChange={(e) => setInstitution(e.target.value)}/>
                <span className="placeholder"><span className='star'>*</span> Institution</span>
            </label>
            <br></br>

            <label className="custom-field one" for="gender">
                <Select placeholder="Gender" className="select" options={genderOption} value={gender} onChange={genderSelect} required />
                <span className="placeholder"><span className='star'>*</span> Gender</span>
            </label>
            <br></br>

            <label className="custom-field one" for="country">
                <Select placeholder="Country" className="select" options={countryOption} value={country} onChange={countrySelect} required/>
                <span className="placeholder"><span className='star'>*</span>Country</span>
            </label>
            <br></br>

            <button id="button" type="button" onClick={handleAdmin}>Complete Setup</button>
        </form>
    
  </div>
    </div>
  );
}
export default Google_profile;
