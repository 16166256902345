import { Link, useMatch, useResolvedPath, useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IoPersonCircleOutline } from 'react-icons/io5';
import { IoPersonCircleSharp } from "react-icons/io5";
// import Dropdown from 'react-bootstrap/Dropdown';

const Navbar = ({ isLoggedIn, onLogout }) => {

  const navigate = useNavigate();

  useEffect(() => {
    console.log('isLoggedIn changed:', isLoggedIn);
  }, [isLoggedIn]);

  const handleLogout = () => {
    onLogout();
    localStorage.setItem('profile', null);
    navigate('/login');
  };

  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);

  // Toggles the visibility of the dropdown content
  const toggleDropdown = () => {
    setIsVisible(!isVisible);
    console.log(isVisible);
  };

  // Click outside the dropdown to close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);
  

  
    return (
      <nav className="navbar">
        <div>
          <Link id="logo" to="/">
          <h2>Biopsy adequacy assessment tool</h2>
          </Link>
        </div>

        <ul className="links">
          <CustomLink to="/">Home</CustomLink>
          {isLoggedIn ? (

            <CustomLink to="/demo">Tool</CustomLink>

        ) : (

            <CustomLink to="/login">Login</CustomLink>

        )}
            <CustomLink to="/about">Help</CustomLink>
            <CustomLink to="/contact">Contact</CustomLink>
            
        </ul>
        {isLoggedIn ? (
          // <button id="logout" onClick={handleLogout}>Log Out</button>
          <div className="dropdown" ref={dropdownRef}>
          {isVisible ? (
            <div>
              <Link id="icon"><IoPersonCircleSharp  size={35} onClick={toggleDropdown} className="dropbtn"/></Link>
              <div className="dropdown-content">
                <ul>
                  <li id="lastLi"><CustomLink to="/profile">Profile</CustomLink></li>
                  <li><CustomLink to="/image">All Images</CustomLink></li>
                  <li onClick={handleLogout}><a>Logout</a></li>
                </ul>
              </div>
            </div>
          ) : (
            <div>
            <Link id="icon"><IoPersonCircleOutline size={35} onClick={toggleDropdown} className="dropbtn"/></Link>
            </div>
          )}
        </div>
            ) : null}
      </nav>  
    );
}

function CustomLink({ to, children, ...props}) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true})
  return(
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
 
export default Navbar;
