import { useState, useEffect } from "react";
import { HOST } from './host';

const Profile = () => {
    // const [profile, setProfile] = useState([]);
    const [firstName, setFN] = useState();
    const [lastName, setLN] = useState();
    const [email, setEmail] = useState();
    const [institution, setInst] = useState();
    const [country, setCountry] = useState();
    const [gender, setGender] = useState();


    useEffect(() => {
        getProfileData();
    }, []);

    
    function getCookie(name) {
        const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(name + '='))
          ?.split('=')[1]; // Get the value of the cookie with the specified name
        return cookieValue ? decodeURIComponent(cookieValue) : null;
    }

    const getProfileData = async () => {
        const savedProfile = await JSON.parse(localStorage.getItem('profile'));
        const uploadData = new FormData();
        uploadData.append('email', savedProfile.email);
        const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
        const headers = {
            'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
        };
        // Fetch all date, informs of: [{input img1, output img1, time+date}, {input img2, output img2, time+date}, ...]
        await fetch(`${HOST}get_user_profile/`, { 
            headers: headers,
            method: 'POST',
            body: uploadData,
        })
        .then(response => response.json())
        .then(data => {
            console.log(data[0]["first_name"]);
            setFN(data[0]["first_name"]);
            setLN(data[0]["last_name"]);
            setEmail(data[0]["email"]);
            setCountry(data[0]["country"]);
            setGender(data[0]["gender"]);
            setInst(data[0]["institution"]);
          })
        .catch(error => {
            console.error('Error fetching input images:', error);
        });
    }


    
    return (
        <div className="profile">
            <h1>Personal Profile</h1>
            <div>
                <table>
                    <tr>
                        <th>First Name:</th>
                        <td>{firstName}</td>
                    </tr>
                    <tr>
                        <th>Last Name:</th>
                        <td>{lastName}</td>
                    </tr>
                    <tr>
                        <th>Email:</th>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <th>Institution:</th>
                        <td>{institution}</td>
                    </tr>
                    <tr>
                        <th>Country:</th>
                        <td>{country}</td>
                    </tr>
                    <tr>
                        <th>Gender:</th>
                        <td>{gender}</td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default Profile;