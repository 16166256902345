import React, { useEffect } from 'react';
import { HOST } from './host';

const Footer = () => {
    useEffect(() => {
        // Fetch data from the API endpoint when the component mounts
        fetch(`${HOST}user_count/`)
          .then(response => response.json())
          .then(userCount => {
            console.log(userCount)
            const userCountElement = document.getElementById('numUsers');
            userCountElement.textContent = `Number of users: ${userCount}`;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });

          // Fetch data from the API endpoint when the component mounts
        fetch(`${HOST}image_count/`)
        .then(response => response.json())
        .then(imageCount => {
          console.log(imageCount)
          const imageCountElement = document.getElementById('numImages');
          imageCountElement.textContent = `Number of images: ${imageCount}`;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      }, []);

      return (
        <div >
            <footer className="footer">
                <div className='numUsers' id='numUsers'>Loading...</div>
                <div className='numUsers' id='numImages'>Loading...</div>
                <div className='numUsers' id='numInst'>Number of institutions: 4</div>
            </footer>
        </div>
      );
}

export default Footer;