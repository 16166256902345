import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Demo from './Demo';
import About from './About';
import Contact from './Contact';
import Login from './Login';
import Register from './Register';
import Google_profile from './Google_profile';
import Footer from './Footer';
import Image from './Image';
import Profile from './Profile';
import React, { useState, useEffect, useContext } from 'react';
            
function App() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  const profile = useContext('');

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') === 'false'
  );

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(loggedIn === 'true');
  }, []);

  return (
    <Router>
      <div className='app'>
        <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home/>} />
            {isLoggedIn ? (<Route path="/demo" element={<Demo/>} />) : (<Route path="/login" element={<Login onLogin={handleLogin}/>} />)}
            <Route path="/register" element={<Register/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/image" element={<Image/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/google_profile" element={<Google_profile onLogin={handleLogin}/>} />
          </Routes>
        </div>
      </div>
      <footer className='app_footer'>
          <Footer></Footer>
      </footer>
    </Router>
  );
}


export default App;