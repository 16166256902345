const Contact = () => {
    return (
        <div className="contact">
            <div className="blocks">
                <h1>Kolachalama laboratory</h1>
                <h2>Boston University</h2>
                <ul>
                    <li><strong>Website: </strong><a href="https://vkola-lab.github.io" target="_blank">https://vkola-lab.github.io/</a></li>
                </ul>
            </div>

            <div className="blocks">
                <h2>Contact us</h2>
                <ul>
                    <li><strong>Email: </strong>info@biopsyadequacy.org</li>
                </ul>
            </div>
        </div>
    );
}
 
export default Contact;
