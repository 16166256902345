const About = () => {
    return (
        <div className="about">
            <div className="blocks">
                <h1>Problem</h1>
                <p>Over the last 15 years, the rate of kidney biopsies that are deficient for diagnosis due to insufficient tissue has risen to 15%, representing a significant drain on the health care system as well as a direct risk to patients who must be re-biopsied or go without an accurate diagnosis.</p>
            </div>

            <div className="blocks">
                <h1>Solution</h1>
                <p>Using a simple picture that can be taken on a smartphone, our AI-based tool provides a real-time adequacy assessment of kidney biopsies in the operating rooms.</p>
            </div>

            <div className="blocks">
                <h1>How It Works</h1>
                <p><b>Quick and Easy:</b> Simply take a photograph of the kidney biopsy in the procedure room and upload it to our platform.</p>
                <p><b>Immediate Analysis:</b> Receive an instant estimate of the cortex area, crucial for assessing biopsy adequacy.</p>
            </div>

            <div className="blocks">
                <h1>Getting Started Is Easy</h1>
                <p><b>First-Time Users:</b> You will be prompted to create an account upon your first visit. If you have a Google account, you can use it for quick authentication.</p>
                <p><b>Returning Users:</b> Log in using your Google account for seamless access.</p>
                <p><b>Taking the Photograph:</b> Clinicians performing kidney biopsies can use a smartphone to capture the image. For optimal results, ensure the image is taken against a light, uniform background.</p>
            </div>

            <div className="blocks">
                <p><b>For more information, see the paper below:</b></p>
                <p><a href="https://doi.org/10.1016/j.ekir.2024.06.019" target="_blank">Pilot study of a web-based tool for real-time adequacy assessment of kidney biopsies</a></p>

            </div>
            {/* <ul>
                <li><strong>Problem:</strong> Over the last 15 years, the rate of kidney biopsies that are deficient for diagnosis due to insufficient tissue has risen to 15%, representing a significant drain on the health care system as well as a direct risk to patients who must be re-biopsied or go without an accurate diagnosis. </li>
                <li><strong>Solution:</strong> Using a simple picture that can be taken on a smartphone, our AI-based tool provides a real-time adequacy assessment of kidney biopsies in the operating rooms.</li>
            </ul> */}
        </div>
    );
}
 
export default About;