import React, { useState, useRef, useEffect } from 'react';
import './css/index.css';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { HOST } from "./host";
// Function to get the CSRF token from the cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

const Demo = () => {
  const [image, setImage] = useState();
  const [im, setIm] = useState();
  const [output_image, setOutputImage] = useState();
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state to track loading status
  const [isDragging, setIsDragging] = useState(false);
  const [imageIsUp, setImageIsUp] = useState(false);
  const [isDoneEval, setIsDoneEval] = useState(false);
  const [imUnCrop, setImUnCrop] = useState();
  const [isCropDone, setIsCropDone] = useState(false);
  const cropperRef = useRef(null);
  const [imageName, setImageName] = useState('');
  // const [imageFile, setImageFile] = useState();



  function handleChange(e) {
    // setImage(e.target.files[0]);
    // setFirst(false);
    // if (!isFirst) {
    //   window.location.reload();
    //   setFirst(true);
    // }
    // if (e.target.files[0].type.startsWith('image/')) {
    //   setIm(URL.createObjectURL(e.target.files[0]));
    setIsCropDone(false);
    setIsDoneEval(false);
    setImUnCrop(URL.createObjectURL(e.target.files[0]));
    const img_name = e.target.files[0].name;
    console.log("Image name:" + img_name)
    const savedProfile = JSON.parse(localStorage.getItem('profile'));

    console.log("User email: " + savedProfile.email);
    setImageName(savedProfile.email + "~" + img_name);
    setOutputImage(null); // Clear the output_image state when a new image is uploaded
    setText(''); // Clear the text state when a new image is uploaded
    e.target.value = '';
    // }
  }

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   let files;
  //   if (e.dataTransfer) {
  //     files = e.dataTransfer.files;
  //   } else if (e.target) {
  //     files = e.target.files;
  //   }
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setIsCropDone(false);
  //     setIsDoneEval(false);
  //     setImUnCrop(reader.result);

  //     setOutputImage(null); // Clear the output_image state when a new image is uploaded
  //     setText(''); // Clear the text state when a new image is uploaded
  //   };
  //   reader.readAsDataURL(files[0]);
  // };

  const newImage = () => {
    setIsLoading(true);
    const imageFile = '';

    const csrftoken = getCookie('csrftoken');
    const headers = {
      'X-CSRFToken': csrftoken,
    };

    const uploadData = new FormData();
    if (image !== null && image !== undefined) {
      // uploadData.append('image', image, image.name);
      const blob = dataURLtoBlob(image);

      uploadData.append('image', blob, imageName);
      // imageFile = new File([blob], imageName, {type: blob.type});
      // uploadData.append('image', imageFile, imageFile.name);
      // uploadData.append('image', image);
      setImageIsUp(true);
    }

    // First, make the request to upload the image
    fetch(`${HOST}media/images/`, {
      method: 'POST',
      headers: headers,
      body: uploadData,
      mode: 'cors',
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((data) => {
        // const imageUrl = `${HOST}media/outputimages/outputimages/${image.name}`;
        const imageUrl = `${HOST}media/outputimages/outputimages/${imageName.split("~")[1]}`;

        // Now make the request to fetch the image
        fetch(imageUrl, {
          method: 'GET',
          headers: {
            ...headers,
            'Cache-Control': 'no-cache',
          },
          mode: 'cors',
        })
          .then((res) => res.blob())
          .then((imageBlob) => {
            setOutputImage(imageUrl);
          })
          .catch((error) => {
            console.error('Error fetching image:', error);
            // Handle the error, e.g., show a message to the user
          });

        // Now make the request to fetch the text
        // fetch(`${HOST}media/calculations/calculations/${(image.name).split(".")[0] + ".txt"}`, {
        fetch(`${HOST}media/calculations/calculations/${(imageName.split("~")[1]).split(".")[0] + ".txt"}`, {
          method: 'GET',
          headers: {
            ...headers,
            'Cache-Control': 'no-cache',
          },
          mode: 'cors',
        })
          .then((res) => res.text())
          .then((data) => {
            setText(data);
          })
          .catch((error) => {
            console.error('Error fetching text:', error);
            // Handle the error, e.g., show a message to the user
          });
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        // Handle the error, e.g., show a message to the user
      })
      .finally(() => {
        setIsLoading(false);
        if (output_image !== null || output_image !== undefined) {
          setIsDoneEval(true);
          console.log(isDoneEval);
        }
      });
  };

  /*
    const newImage = () => {
  
      setIsLoading(true); // Set isLoading to true when new image is submitted
        const HOST = 'https://biosmart.bu.edu/api/'; 
      const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from the cookie
      const headers = {
        'X-CSRFToken': csrftoken, // Include the CSRF token in the headers
     //   'Content-Type': 'multipart/form-data',
      };
  
      const uploadData = new FormData();
      if (image !== null && image !== undefined) {
        uploadData.append('image', image, image.name);
        setImageIsUp(true); // Set imageIsUp to true when image is submitted
      }
      
      // First, make the request to upload the image
      fetch(`${HOST}media/images/`, {
        method: 'POST',
        headers: headers, // Include the headers with the CSRF token
        body: uploadData,
        mode: 'cors',
      })
        .then((res) => {
          console.log(res);
          return res.json();
        })
        .then((data) => {
          // Assuming the response from the backend contains the evaluated image name
          console.log(image.name);
  
          const imageUrl = `${HOST}media/outputimages/outputimages/${image.name}`;
        fetch(imageUrl, {
          method: 'GET',
          headers: {
      ...headers,
      'Cache-Control': 'no-cache',
          },
          mode: 'cors',
        })
        .then((res) => res.blob())
        .then((imageBlob) => {
          setOutputImage(imageUrl);
        })
        .catch((error) => console.error(error));
  
          console.log(image.name);
          fetch(`${HOST}media/calculations/calculations/${(image.name).split(".")[0] + ".txt"}`, {
            method: 'GET',
          headers: {
      ...headers,
      'Cache-Control': 'no-cache', 
          }, 
            mode: 'cors',
          })
            .then((res) => res.text())
            .then((data) => {
              setText(data); // Update the text state with the received content
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false); // Set isLoading back to false once image is loaded
        });
    };
  */

  // handling zoom in & zoom out & drag
  const [scale1, setScale1] = useState(1);
  const [scale2, setScale2] = useState(1);
  const [position1, setPosition1] = useState({ x: 0, y: 0 });
  const [position2, setPosition2] = useState({ x: 0, y: 0 });

  const handleZoomIn1 = () => {
    setScale1(scale1 + 0.1);
  };

  const handleZoomIn2 = () => {
    setScale2(scale2 + 0.1);
  };

  const handleZoomOut1 = () => {
    setScale1(scale1 - 0.1);
  };

  const handleZoomOut2 = () => {
    setScale2(scale2 - 0.1);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseMove1 = (e) => {
    if (isDragging) {
      setPosition1({
        x: position1.x + e.movementX / scale1,
        y: position1.y + e.movementY / scale1,
      });
    }
  };

  const handleMouseMove2 = (e) => {
    if (isDragging) {
      setPosition2({
        x: position2.x + e.movementX / scale2,
        y: position2.y + e.movementY / scale2,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const getCropData = () => {
    if (imUnCrop !== null && imUnCrop !== undefined) {
      if (typeof cropperRef.current?.cropper !== "undefined") {
        setImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        setIm(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
        setIsCropDone(true);
      }
    } else {
      alert('Please upload an image first.');
    }
  };

  return (
    <div className="demo">
      <div id="description">
        <ol>
          <li>Click on the "Upload image" button.</li>
          <li>Drag the square cropping tool over the image to split the main biopsy part of the image.</li>
          <li>Click on the "Crop Image" button.</li>
          <li>Click on the "Evaluate" button to see the annotated biopsy image and the cortex percentage.</li>
        </ol>
      </div>

      <div className="upload">
        <label>
          Upload Image
          <input style={{ display: "none" }} type="file" onChange={handleChange} />
        </label>
      </div>

      <div id="zoombutton">
        {isCropDone ? (
          <div id="inputb">
            <button id="b1" type="button" onClick={handleZoomIn1}>Input Zoom In</button>
            <button id="b2" type="button" onClick={handleZoomOut1}>Input Zoom Out</button>
          </div>
        ) : (
          <button onClick={getCropData}>Crop Image</button>
        )}
        <button id="b3" type="button" onClick={handleZoomIn2}>Output Zoom In</button>
        <button id="b4" type="button" onClick={handleZoomOut2}>Output Zoom Out</button>

      </div>

      <div id="images">
        <div id="Inimg">
          {isCropDone ? (
            <img id="inimg" src={im} alt="" style={{ width: `${100 * scale1}%`, transform: `translate(${position1.x}px, ${position1.y}px)`, cursor: isDragging ? 'grabbing' : 'grab', }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove1}
              onMouseUp={handleMouseUp} />
          ) : (
            <Cropper
              ref={cropperRef}
              initialAspectRatio={1}
              aspectRatio={1 / 1}
              preview=".img-preview"
              src={imUnCrop}
              minCropBoxHeight={1}
              minCropBoxWidth={1}
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} //https://github.com/fengyuanchen/cropperjs/issues/671
              guides={true}
            />
          )}
        </div>
        <div id="Outimg">
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {imageIsUp ? <div className="loader"></div> : null}
            </div>
          ) : (
            <>
              <img id="outimg" src={output_image} width="80%" alt="" style={{ width: `${100 * scale2}%`, transform: `translate(${position2.x}px, ${position2.y}px)`, cursor: isDragging ? 'grabbing' : 'grab', }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove2}
                onMouseUp={handleMouseUp} />
            </>
          )}
        </div>
        {isDoneEval ? (
          <div id="output-text">
            <pre>Cortex percentage: <span style={{ color: 'red' }}>{text} </span></pre>
          </div>
        ) : null}
      </div>

      <div id="segment">
        {isCropDone ? (<button onClick={newImage}>Evaluate</button>) : (<button disabled style={{ background: '#622e80' }}>Evaluate</button>)}
      </div>
    </div>
  );
}

export default Demo;
